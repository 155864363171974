<template>
  <div class="" id="renew-guide-wizard-email-step">
    <b-alert show variant="success">
      <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.EMAIL_OPTION_HEADER') }}</h4>
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.EMAIL_OPTION_INFO1') }}
      </p>

    </b-alert>

    <div class="form-group">
      <div class="radio-list">
        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.email_option"
            value="DO_SEND"
            name="EMAIL_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.EMAIL_OPTION_YES') }}
        </label>

        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.email_option"
            value="DONT_SEND"
            name="EMAIL_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.EMAIL_OPTION_NO') }}
        </label>
      </div>
    </div>

    <b-row v-if="local_options.email_option === 'DO_SEND'">
      <b-col cols="12">
        <b-form ref="form" @submit.prevent="on_submit_email_form">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('MEMBER_RENEW.WIZARD.EMAIL_TEMPLATE_INFO')"
          >
              <b-form-select
                v-model="local_options.email_template_id"
                :options="templates"
                @change="check_validity"
                :state="email_template_id_state"
              />
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <div v-if="local_options.email_template_id">
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.EMAIL_NUM_REMINDERS_INFO') }}
      </p>

      <b-form ref="form" @submit.prevent="on_submit_email_form">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER_RENEW.WIZARD.EMAIL_NUM_REMINDERS')"
            >
              <b-form-select
                v-model="local_options.num_email_reminders"
                :options="num_reminders_options"
                :state="num_email_reminders_state"
                @change="check_validity"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

    </div>

    <div v-if="local_options.num_email_reminders > 0">

      <b-form ref="form" @submit.prevent="on_submit_email_form">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER_RENEW.WIZARD.EMAIL_TEMPLATE_REMINDER_INFO')"
            >
              <b-form-select
                v-model="local_options.email_reminder_template_id"
                :options="templates"
                :state="email_reminder_template_id_state"
                @change="check_validity"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div v-if="local_options.email_reminder_template_id !== null">
        <b-form ref="form" @submit.prevent="on_submit_email_form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.EMAIL_NUM_DAYS_WAIT')"
              >
                <b-form-select
                  v-model="local_options.email_num_days_wait"
                  :options="num_days_options"
                  :state="email_num_days_wait_state"
                  @change="check_validity"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

    </div>

  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

:deep .alert.alert-success {
  background-color: #d1f0ff;
  border-color: #d1f0ff;
  color: #1d3d6f;
}

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { useVuelidate } from '@vuelidate/core';
import { validate_state } from '@/core/services/validations.service';
import { required } from '@vuelidate/validators';


export default {
  name: 'renew-guide-wizard-email-step',
  components: {},
  props: ['options','templates','name'],
  emits: ['on_check_validity'],
  mixins: [ toasts ],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      local_options: {
        email_template_id: { required },
        num_email_reminders: { required },
        email_reminder_template_id: { required },
        email_num_days_wait: { required }
      }
    };
  },
  computed: {
    is_infinite_period() {
      return this.periods.length === 0;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    is_valid() {
      return this.local_options.email_option === 'DONT_SEND' || (this.local_options.email_option === 'DO_SEND' && this.local_options.email_template_id !== null);
    },
    email_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.email_template_id.$dirty, error: this.v$.local_options.email_template_id.$error })
    },
    num_email_reminders_state() {
      return validate_state({ dirty: this.v$.local_options.num_email_reminders.$dirty, error: this.v$.local_options.num_email_reminders.$error })
    },
    email_reminder_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.email_reminder_template_id.$dirty, error: this.v$.local_options.email_reminder_template_id.$error })
    },
    email_num_days_wait_state() {
      return validate_state({ dirty: this.v$.local_options.email_num_days_wait.$dirty, error: this.v$.local_options.email_num_days_wait.$error })
    }

  },
  mounted: function() {
    if (this.options) {
      this.local_options = { ...this.options };
    }
    this.$nextTick(()=>{
      this.check_validity();
    });
  },
  data() {
    return {
      local_options: {},
      value_of_email_template_id: null,

      email_template_id: null,

      num_reminders_options: [
        {
          value: 0,
          text: '0'
        },
        {
          value: 1,
          text: '1'
        },
        {
          value: 2,
          text: '2'
        },
        {
          value: 3,
          text: '3'
        }
      ],

      num_days_options:  [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 11, text: '11' },
        { value: 12, text: '12' },
        { value: 13, text: '13' },
        { value: 14, text: '14' },
      ],

    };
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        this.local_options = { ...val };
      }
    }
  },
  methods: {
    check_validity() {
      this.$emit('on_check_validity', this.name, this.is_valid, this.options);
    },

    reset_validation() {
      if (this.item) {
        this.$nextTick(() => this.v$.$reset());
      }
    },

    async validate() {
      return await this.v$.$validate();
    },

    on_submit_email_form() {},
  }
};
</script>
