<template>
  <div class="px-5 mb-8" id="page-renew-guide-wizard">

    <h4 class="mb-8 ml-2 mt-8">{{ $t('MEMBER_RENEW.WIZARD.HEADER') }}</h4>

    <b-card title="" class="mb-2" hide-footer>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps py-8">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1</span> {{ $t('MEMBER_RENEW.WIZARD.INTRO_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>2</span> {{ $t('MEMBER_RENEW.WIZARD.PERIOD_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>3</span> {{ $t('MEMBER_RENEW.WIZARD.EMAIL_OPTION_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>4</span> {{ $t('MEMBER_RENEW.WIZARD.SMS_OPTION_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>5</span> {{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>6</span> {{ $t('MEMBER_RENEW.WIZARD.MEMBERSHIP_OPTION_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>7</span> {{ $t('MEMBER_RENEW.WIZARD.SCHEDULE_HEADER') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step" data-wizard-state="pending">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>8</span> {{ $t('SAMPLE.CONFIRM') }}</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--Begin: Introduction page -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <b-alert show variant="success">
                  <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.INTRO_HEADER') }}</h4>
                  <p>
                    {{ $t('MEMBER_RENEW.WIZARD.INTRO_INFO1') }}
                  </p>
                  <hr>
                  <p class="mb-0">
                    {{ $t('MEMBER_RENEW.WIZARD.INTRO_INFO2') }}
                  </p>
                </b-alert>

              </div>
              <!--End: Introduction page -->

              <!--Begin: select companies page -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">

                <b-alert show variant="success">
                  <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.PERIOD_HEADER') }}</h4>
                  <p>
                    {{ $t('MEMBER_RENEW.WIZARD.PERIOD_INFO1', { type: is_infinite_period ? $t('MEMBER_RENEW.PERIOD_TYPE_INFINITE') : $t('MEMBER_RENEW.PERIOD_TYPE_PERIOD') }) }}
                  </p>

                  <p v-if="is_infinite_period">
                    {{ $t('MEMBER_RENEW.WIZARD.PERIOD_INFINITE_INFO') }}
                  </p>
                  <p v-else>
                    {{ $t('MEMBER_RENEW.WIZARD.PERIOD_PERIOD_INFO') }}
                  </p>

                  <hr>

                  <p v-if="!is_infinite_period">
                    {{ $t('MEMBER_RENEW.WIZARD.SELECTED_PERIOD_INFO', { from_period: selected_period.name, to_period: next_period.name }) }}
                  </p>
                  <p class="mb-0">
                    {{ $t('MEMBER_RENEW.WIZARD.PERIOD_INFO2') }}
                  </p>
                </b-alert>

              </div>
              <!--End: select companies page -->

              <!--Begin: email step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">

                <RenewGuideWizardEmailStep
                  ref="email"
                  :name="'email'"
                  :options="options"
                  :templates="email_template_options"
                  @on_check_validity="on_check_validity"
                />

              </div>
              <!--End: email step -->

              <!--Begin: sms step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">

                <RenewGuideWizardSMSStep
                  ref="sms"
                  :name="'sms'"
                  :options="options"
                  :templates="sms_template_options"
                  @on_check_validity="on_check_validity"
                />

              </div>
              <!--End: sms step -->

              <!--Begin: invoice step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">
                <RenewGuideWizardInvoiceStep
                  ref="invoice"
                  :name="'invoice'"
                  :options="options"
                  :templates="invoice_template_options"
                  @on_check_validity="on_check_validity"
                />
              </div>
              <!--End: invoice step -->

              <!--Begin: memberships step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">

                <RenewGuideWizardMembershipStep
                  ref="memberships"
                  :name="'memberships'"
                  :options="options"
                  @on_check_validity="on_check_validity"
                />


              </div>
              <!--End: memberships step -->

              <!--Begin: schedule step -->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="pending">

                <RenewGuideWizardScheduleStep
                  ref="schedule"
                  :name="'schedule'"
                  :options="options"
                  @on_check_validity="on_check_validity"
                />


              </div>
              <!--End: schedule step -->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                  {{$t('PAGES.REGISTRATION.PREV_STEP')}}
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 text-right"
                    data-wizard-type="action-next"
                    :disabled="!next_button_enabled"
                    v-on:click="nextPage"
                  >
                  {{$t('PAGES.REGISTRATION.NEXT_STEP')}}
                  </button>
                </div>

                <button
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-ktwizard-type="action-submit"
                  v-on:click="finish_guide_clicked"
                  v-if="currentPage === 8"
                >
                {{ $t('SAMPLE.CREATE') }}
                </button>
              </div>
              <!--end: Wizard Actions -->
            </form>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

:deep .alert.alert-success {
  background-color: #d1f0ff;
  border-color: #d1f0ff;
  color: #1d3d6f;
}

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import KTUtil from '@/assets/js/components/util';
import KTWizard from '@/assets/js/components/wizard';

import RenewGuideWizardEmailStep from './RenewGuideWizardEmailStep.vue';
import RenewGuideWizardSMSStep from './RenewGuideWizardSMSStep.vue';
import RenewGuideWizardInvoiceStep from './RenewGuideWizardInvoiceStep.vue';
import RenewGuideWizardMembershipStep from './RenewGuideWizardMembershipStep.vue';
import RenewGuideWizardScheduleStep from './RenewGuideWizardScheduleStep.vue';


export default {
  name: 'renew-guide-wizard',
  components: {
    RenewGuideWizardEmailStep,
    RenewGuideWizardSMSStep,
    RenewGuideWizardInvoiceStep,
    RenewGuideWizardMembershipStep,
    RenewGuideWizardScheduleStep
},
  props: [],
  mixins: [ toasts ],
  computed: {
    is_infinite_period() {
      return this.periods.length === 0;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    selected_period() {
      return this.periods.find(item => item.id === this.currentPeriodId);
    },
    next_period() {
      return this.periods.find(item => item.id === this.selected_period.next_period_id);
    },
    disabledNextButton() {
      if (this.wizard) {
        switch (this.wizard.currentStep) {
          case 2:
          case 3:
          case 4:
          case 5:
          default:
            return false;
        }
      } else return false;
    }
  },
  mounted: function() {
    this.load_templates();
    this.load_invoice_templates();

    this.createWizard();
  },
  data() {
    return {
      sms_template_options: [],
      email_template_options: [],
      templates: [],

      invoice_template_options: [],

      options: {
        sms_option: null, // DO_SEND, DONT_SEND
        email_option: null, // DO_SEND, DONT_SEND
        num_email_reminders: null, // 0 - 4
        num_sms_reminders: null,  // 0 - 4
        email_template_id: null, // ml_template
        sms_template_id: null, // ml_template
        invoice_template_id: null, // ml_invoice_template
        email_reminder_template_id: null, // ml_template
        sms_reminder_template_id: null, // ml_template
        invoice_reminder_template_id: null, // ml_invoice_template
        email_num_days_wait: null, // 0 - 14
        sms_num_days_wait: null, // 0 - 14
        invoice_num_days_wait: null, // 0 - 14
        invoice_option: null, // DIRECT, LATER, NONE
        num_invoice_reminders: null, // 0 - 4
        invoice_send_option: null, // MEMLIST, CUSTOMER
        schedule_option: null, // AUTORENEW, AT_DATE
        schedule_at_date: null, // date to create renews if AT_DATE
      },

      num_reminders_options: [
        {
          value: 0,
          text: '0'
        },
        {
          value: 1,
          text: '1'
        },
        {
          value: 2,
          text: '2'
        },
        {
          value: 3,
          text: '3'
        }
      ],

      num_days_options:  [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 11, text: '11' },
        { value: 12, text: '12' },
        { value: 13, text: '13' },
        { value: 14, text: '14' },
      ],

      next_button_enabled: true,

      current_step_name: 'intro',

      sendEmail: false,
      sendSMS: false,

      created: false,
      sendoutOptions: [],
      form: {},
      showNext: true,
      currentPage: 0,
      wizard: null,
      createOption: 'split',
      companySelection: [],
      company: { id: '', name: '' },
      pct_members: 0,
      pct_members_text: '0 %',
      num_members_text: 0,
      num_members_total: 30000,
      showPreviewSms: false,
      showPreviewEmail: false,
      filterCompanies: [],
      counts: {},
    };
  },
  watch: {

    showNext(newVal, oldVal) {

    }
  },
  methods: {
    finish_guide_clicked() {},

    on_check_validity(step_name, is_valid, options) {

      if (this.current_step_name === step_name) {
        this.next_button_enabled = is_valid;
      }

    },

    on_submit_email_form() {},

    async load_templates() {
      this.templates = [];
      this.sms_template_options = [];
      this.email_template_options = [];

      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`)


        if (res.status === 200) {
          this.templates = res.data;
        }

        for (var i = 0; i < this.templates.length; ++i) {
          if (!this.templates[i].is_sms) {
            this.email_template_options.push({
              value: this.templates[i].template_id,
              text: this.templates[i].name
            });
          } else {
            this.sms_template_options.push({
              value: this.templates[i].template_id,
              text: this.templates[i].name
            });
          }
        }

        return;
      }
      catch (err) {
        console.error('load_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));

    },


    async load_invoice_templates() {

      try {
        const res = await axios.get(`/invoice_template`)


        if (res.status === 200) {
          const templates = res.data;

          for (var i = 0; i < templates.length; ++i) {
            this.invoice_template_options.push({
              value: templates[i].id,
              text: templates[i].name
            });
          }
        }

        return;
      }
      catch (err) {
        console.error('load_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));

    },



    nextPage() {
      // make sure that the next button is properly disabled when clicking on next
      // this.wizard.getStep()
      if (this.wizard.currentStep === 1) {

      }

    },

    createWizard() {
      var that = this;

      that.showNext = true;

      // Initialize form wizard
      that.wizard = new KTWizard('kt_wizard_v3', {
        startStep: 1, // initial active step number
        clickableSteps: true // allow step clicking
      });

      // Validation before going to next page
      that.wizard.on('beforeNext', function(/*wizardObj*/) {
        // validate the form and use below function to stop the wizard's step
        // wizardObj.stop();
      });

      // Change event
      that.wizard.on('change', function(/*wizardObj*/) {

        setTimeout(() => {
          KTUtil.scrollTop();

          that.currentPage = that.wizard.currentStep;

          switch (that.currentPage) {
            case 1:
              that.current_step_name = 'intro';
              break;
            case 2:
              that.current_step_name = 'period';
              break;
            case 3:
              that.current_step_name = 'email';
              break;
            case 4:
              that.current_step_name = 'sms';
              break;
            case 5:
              that.current_step_name = 'invoice';
              break;
            case 6:
              that.current_step_name = 'memberships';
              break;
            case 6:
              that.current_step_name = 'schedule';
              break;
            default:
              that.current_step_name = 'default';
              break;
          }

          if (that.$refs[that.current_step_name]) {
            that.$refs[that.current_step_name].check_validity();
          }
          else {
            this.next_button_enabled = true;
          }

          // if (that.currentPage === 2 && that.createOption === null) {
          //   that.disabledNextButton = true;
          // }
        }, 200);
      });

      that.showNext = false;
    },



  }
};
</script>
