<template>
  <div class="" id="renew-guide-wizard-email-step">
    <b-alert show variant="success">
      <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_HEADER') }}</h4>
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_INFO1') }}
      </p>

    </b-alert>

    <div class="form-group">
      <div class="radio-list">
        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.invoice_option"
            value="DIRECT"
            name="INVOICE_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_YES_DIRECT') }}
        </label>

        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.invoice_option"
            value="LATER"
            name="INVOICE_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_YES_LATER') }}
        </label>

        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.invoice_option"
            value="NONE"
            name="INVOICE_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.INVOICE_OPTION_NO') }}
        </label>
      </div>
    </div>

    <b-alert show variant="danger" v-if="local_options.invoice_option === 'DIRECT'">
      <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.INVOICE_DIRECT_DANGER_HEADER') }}</h4>
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.INVOICE_DIRECT_DANGER_INFO') }}
      </p>

    </b-alert>

    <div v-if="local_options.invoice_option === 'DIRECT' || local_options.invoice_option === 'LATER'">
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.INVOICE_SEND_OPTION_INFO') }}
      </p>
      <div class="form-group">
        <div class="radio-list">
          <label class="radio radio-outline radio-success mb-4">
            <input
              type="radio"
              v-model="local_options.invoice_send_option"
              value="MEMLIST"
              name="INVOICE_SEND_OPTION"
              @change="check_validity"
            />
            <span></span>
            {{ $t('MEMBER_RENEW.WIZARD.INVOICE_SEND_OPTION_OPTION_YES') }}
          </label>

          <label class="radio radio-outline radio-success mb-4">
            <input
              type="radio"
              v-model="local_options.invoice_send_option"
              value="CUSTOMER"
              name="INVOICE_SEND_OPTION"
              @change="check_validity"
            />
            <span></span>
            {{ $t('MEMBER_RENEW.WIZARD.INVOICE_SEND_OPTION_OPTION_NO') }}
          </label>

        </div>
      </div>

      <b-row v-if="local_options.invoice_send_option !== null">
        <b-col cols="12">
          <b-form ref="form" @submit.prevent="on_submit_email_form">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER_RENEW.WIZARD.INVOICE_TEMPLATE_INFO')"
            >
              <b-form-select
                v-model="local_options.invoice_template_id"
                :options="templates"
                :state="invoice_template_id_state"
                @change="check_validity"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>

      <div v-if="local_options.invoice_template_id">
        <p>
          {{ $t('MEMBER_RENEW.WIZARD.INVOICE_NUM_REMINDERS_INFO') }}
        </p>

        <b-form ref="form" @submit.prevent="on_submit_email_form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.INVOICE_NUM_REMINDERS')"
              >
                <b-form-select
                  v-model="local_options.num_invoice_reminders"
                  :options="num_reminders_options"
                  :state="num_invoice_reminders_state"
                  @change="check_validity"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

      </div>

      <div v-if="local_options.num_invoice_reminders > 0">

        <b-form ref="form" @submit.prevent="on_submit_email_form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.INVOICE_TEMPLATE_REMINDER_INFO')"
              >
                <b-form-select
                  v-model="local_options.invoice_reminder_template_id"
                  :options="templates"
                  :state="invoice_reminder_template_id_state"
                  @change="check_validity"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <div v-if="local_options.invoice_reminder_template_id !== null">

          <b-form ref="form" @submit.prevent="on_submit_email_form">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('MEMBER_RENEW.WIZARD.INVOICE_REMINDER_NUM_DAYS_WAIT')"
                >
                  <b-form-select
                    v-model="local_options.invoice_num_days_wait"
                    :options="num_days_options"
                    :state="invoice_num_days_wait_state"
                    @change="check_validity"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

:deep .alert.alert-success {
  background-color: #d1f0ff;
  border-color: #d1f0ff;
  color: #1d3d6f;
}

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';


export default {
  name: 'RenewGuideWizardInvoiceStep',
  components: {},
  props: ['options','templates','name'],
  emits: ['on_check_validity'],
  mixins: [ toasts ],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      local_options: {
        invoice_template_id: { required },
        num_invoice_reminders: { required },
        invoice_reminder_template_id: { required },
        invoice_num_days_wait: { required },
      }
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    invoice_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.invoice_template_id.$dirty, error: this.v$.local_options.invoice_template_id.$error })
    },
    num_invoice_reminders_state() {
      return validate_state({ dirty: this.v$.local_options.num_invoice_reminders.$dirty, error: this.v$.local_options.num_invoice_reminders.$error })
    },
    invoice_reminder_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.invoice_reminder_template_id.$dirty, error: this.v$.local_options.invoice_reminder_template_id.$error })
    },
    invoice_num_days_wait_state() {
      return validate_state({ dirty: this.v$.local_options.invoice_num_days_wait.$dirty, error: this.v$.local_options.invoice_num_days_wait.$error })
    },
    is_infinite_period() {
      return this.periods.length === 0;
    },
    is_valid() {
      return this.local_options.invoice_option === 'NONE';
    },

  },
  mounted: function() {
    if (this.options) {
      this.local_options = { ...this.options };
    }
    this.$nextTick(()=>{
      this.check_validity();
    });
  },
  data() {
    return {
      local_options: {},
      num_reminders_options: [
        {
          value: 0,
          text: '0'
        },
        {
          value: 1,
          text: '1'
        },
        {
          value: 2,
          text: '2'
        },
        {
          value: 3,
          text: '3'
        }
      ],

      num_days_options:  [
        { value: 14, text: '14' },
        { value: 15, text: '15' },
        { value: 16, text: '16' },
        { value: 17, text: '17' },
        { value: 18, text: '18' },
        { value: 19, text: '19' },
        { value: 20, text: '20' },
        { value: 21, text: '21' },
        { value: 22, text: '22' },
        { value: 23, text: '23' },
        { value: 24, text: '24' },
        { value: 25, text: '25' },
        { value: 26, text: '26' },
        { value: 27, text: '27' },
        { value: 28, text: '28' },
        { value: 29, text: '29' },
        { value: 30, text: '30' },
        { value: 40, text: '40' },
        { value: 50, text: '50' },
        { value: 60, text: '60' },
      ],

    };
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        this.local_options = { ...val };
      }
    }
  },
  methods: {
    check_validity() {
      this.$emit('on_check_validity', this.name, this.is_valid, this.local_options);
    },

    reset_validation() {
      if (this.item) {
        this.$nextTick(() => this.v$.$reset());
      }
    },

    async validate() {
      return await this.v$.$validate();
    },

    on_submit_email_form() {},

  }
};
</script>
