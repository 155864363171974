<template>
  <div class="" id="renew-guide-wizard-membership-step">

    <b-alert show variant="success">
      <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.SCHEDULE_HEADER') }}</h4>
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.SCHEDULE_INFO') }}
      </p>

    </b-alert>


    <div class="form-group">
      <div class="radio-list">
        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.schedule_option"
            value="AUTORENEW"
            name="SCHEDULE_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.SCHEDULE_OPTION_AUTORENEW') }}
        </label>

        <label class="radio radio-outline radio-success mb-4">
          <input
            type="radio"
            v-model="local_options.schedule_option"
            value="AT_DATE"
            name="SCHEDULE_OPTION"
            @change="check_validity"
          />
          <span></span>
          {{ $t('MEMBER_RENEW.WIZARD.SCHEDULE_OPTION_SCHEDULE') }}
        </label>
      </div>
    </div>

    <div v-if="local_options.schedule_option === 'AT_DATE'">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('MEMBER_RENEW.WIZARD.SCHEDULE_AT_DATE')">

              <memlist-date-picker
                v-model="local_options.schedule_at_date"
                id="field-from_date"

                @change="check_validity"
              ></memlist-date-picker>

          </b-form-group>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

:deep .alert.alert-success {
  background-color: #d1f0ff;
  border-color: #d1f0ff;
  color: #1d3d6f;
}

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'renew-guide-wizard-schedule-step',
  components: {

  },
  props: ['options','name'],
  emits: ['on_check_validity'],
  mixins: [ toasts ],
  computed: {
    is_infinite_period() {
      return this.periods.length === 0;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    is_valid() {

      return true;
    },

  },
  mounted: function() {
    if (this.options) {
      this.local_options = { ...this.options };
    }
    this.$nextTick(()=>{
      this.check_validity();
    });
  },
  data() {
    return {
      local_options: {},
    };
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        this.local_options = { ...val };
      }
    }
  },
  methods: {

    check_validity() {
      this.$emit('on_check_validity', this.name, this.is_valid, this.local_options);
    },

    on_submit_email_form() {},

  }
};
</script>
